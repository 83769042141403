<template>
  <div>
    <p class="text-2xl mb-6">
      {{
        editing
          ? `Editer la permission : ${permission.name}`
          : "Créer une nouvelle permission"
      }}
    </p>
    <v-card class="pt-7 pb-3">
      <v-row class="ma-3">
        <v-card-text>
          <v-form ref="form">
            <v-row>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="permission.name"
                    dense
                    label="Nom"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="permission.subject"
                    dense
                    label="Subject"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-select
                    v-model="permission.action"
                    :items="actionsList"
                    outlined
                    dense
                    label="Action"
                    :rules="[required]"
                  ></v-select>
                </div>
              </v-col>
              <v-col cols="12" sm="8">
                <div class="mb-4 d-flex align-center">
                  <v-text-field
                    outlined
                    v-model="permission.description"
                    dense
                    label="Description"
                    :rules="[required]"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  class="me-3 mt-3"
                  @click.native="savePermission"
                >
                  Enregistrer la Permission</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  :to="{ name: 'permissions-list' }"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { required, integerValidator } from "@core/utils/validation";
import axios from "@axios";
import router from "@/router";
export default {
  name: "PermissionsCreateOrUpdate",
  async created() {
    if ("id" in this.$route.params) {
      this.getPermissionById(this.$route.params.id);
      this.editing = true;
    } else {
      this.editing = false;
    }
  },
  data() {
    return {
      required,
      integerValidator,
      editing: false,
      actionsList: ["read", "manage", "no_action"],
      permission: {
        pk: -1,
        name: "",
        subject: "",
        action: "manage",
        description: "",
      },
      projectsList: [],
    };
  },
  methods: {
    async fetchData(url) {
      const { data } = await axios.get(url);
      return data.items;
    },
    async savePermission() {
      const isFormValid = this.$refs.form.validate();
      if (!isFormValid) return;
      const payload = {
        name: this.permission.name,
        subject: this.permission.subject,
        action: this.permission.action,
        description: this.permission.description,
      };

      if (this.editing) {
        const { status } = await axios.put(
          `/permissions/${this.permission.pk}`,
          payload
        );
        if (status === 200) {
          await router.push({ name: "permissions-list" });
        }
      } else {
        const { status } = await axios.post(`/permissions/`, payload);
        if (status === 200) {
          await router.push({ name: "permissions-list" });
        }
      }
    },
    capitalizeFirstLetters(str) {
      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    },
    async getPermissionById(id) {
      const { status, data } = await axios.get(`/permissions/${id}`);
      if (status === 200) {
        this.permission = {
          pk: data.id,
          name: data.name,
          subject: data.subject,
          action: data.action,
          description: data.description,
        };
      }
    },
  },
  watch: {
    "permission.subject"() {
      this.permission.name = this.capitalizeFirstLetters(
        `${this.permission.subject} ${this.permission.action}`
      );
      this.permission.description = this.capitalizeFirstLetters(
        `${this.permission.subject} ${this.permission.action}`
      );
    },
    "permission.action"() {
      this.permission.name = this.capitalizeFirstLetters(
        `${this.permission.subject} ${this.permission.action}`
      );
      this.permission.description = this.capitalizeFirstLetters(
        `${this.permission.subject} ${this.permission.action}`
      );
    },
  },
};
</script>

<style scoped></style>
